import React, { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  getDocs,
  query,
} from "firebase/firestore/lite";
import moment from "moment";

const SVUsersPage = ({ usuario }) => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [subscriptionFilter, setSubscriptionFilter] = useState("all");
  const [currentPage, setCurrentPage] = useState(1); // Página actual
  const usersPerPage = 20; // Número de usuarios por página

  useEffect(() => {
    const db = getFirestore();
    const queryUsers = query(collection(db, "Users"));
    getDocs(queryUsers).then((res) => {
      const wUsers = [];
      res.forEach((doc) => {
        wUsers.push({
          ...doc.data(),
          id: doc.id,
          activeDate: formatDate(doc.data().activeDate),
        });
      });
      setUsers(wUsers);
      setFilteredUsers(wUsers); // Inicialmente, todos los usuarios se muestran sin filtros
    });
  }, []);

  function formatDate(date) {
    return date ? moment(date.toDate()).format("DD/MM/YYYY HH:mm") : "";
  }

  // Filtrar usuarios por nombre, email y estado de suscripción
  useEffect(() => {
    let filtered = users;

    // Filtrar por texto de búsqueda (nombre o email)
    if (searchText) {
      filtered = filtered.filter(
        (user) =>
          user.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
          user.lastName.toLowerCase().includes(searchText.toLowerCase()) ||
          user.email.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    // Filtrar por estado de suscripción
    if (subscriptionFilter === "subscribed") {
      filtered = filtered.filter((user) => user.isSubscriptionActive);
    } else if (subscriptionFilter === "notSubscribed") {
      filtered = filtered.filter((user) => !user.isSubscriptionActive);
    }

    setFilteredUsers(filtered);
    setCurrentPage(1); // Resetear a la primera página al aplicar filtros
  }, [searchText, subscriptionFilter, users]);

  // Obtener los usuarios de la página actual
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  // Cambiar de página
  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      {usuario.isAdmin && (
        <>
          {/* Filtros */}
          <div className="filters mb-3">
            <input
              type="text"
              className="form-control mb-2"
              placeholder="Filtrar per nom o email"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <select
              className="form-select w-25"
              value={subscriptionFilter}
              onChange={(e) => setSubscriptionFilter(e.target.value)}
            >
              <option value="all">Tots</option>
              <option value="subscribed">Subscrit</option>
              <option value="notSubscribed">No Subscrit</option>
            </select>
          </div>

          {/* Taula d'usuaris */}
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Nom</th>
                <th scope="col">Email</th>
                <th scope="col">Subscrit</th>
                <th scope="col">Administrador</th>
              </tr>
            </thead>
            <tbody className="table-group-divider">
              {currentUsers.map((user) => (
                <tr key={user.id}>
                  <th scope="row">{user.firstName + " " + user.lastName} </th>
                  <td>{user.email}</td>
                  <td>
                    {user.isSubscriptionActive && (
                      <>
                        <i className="bi bi-check-circle-fill iconIsActivated">
                          - {user.activeDate}
                        </i>
                      </>
                    )}
                    {!user.isSubscriptionActive && (
                      <>
                        <i className="bi bi-x-circle-fill iconIsNotActivated">
                          - {user.activeDate}
                        </i>
                      </>
                    )}
                  </td>
                  <td>
                    {user.isAdmin && (
                      <i className="bi bi-check-circle-fill iconIsActivated"></i>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Paginación */}
          <nav>
            <ul className="pagination">
              <li
                className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
              >
                <button
                  className="page-link"
                  onClick={() => paginate(currentPage - 1)}
                >
                  Anterior
                </button>
              </li>
              {Array.from({ length: totalPages }, (_, index) => (
                <li
                  key={index + 1}
                  className={`page-item ${
                    currentPage === index + 1 ? "active" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => paginate(index + 1)}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}
              <li
                className={`page-item ${
                  currentPage === totalPages ? "disabled" : ""
                }`}
              >
                <button
                  className="page-link"
                  onClick={() => paginate(currentPage + 1)}
                >
                  Següent
                </button>
              </li>
            </ul>
          </nav>
        </>
      )}
    </>
  );
};

export default SVUsersPage;
