import { React, useState, useEffect } from "react";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import Gallery from "react-photo-gallery";
import Modal from "react-bootstrap/Modal"; // Importar el Modal de react-bootstrap
import "./Styles/SVComponents.css";

function SVShowPhotoGallery({ show }) {
  const [photos, setPhotos] = useState([]);
  const [currentImage, setCurrentImage] = useState(null); // Controlamos la imagen actual seleccionada
  const [isModalOpen, setIsModalOpen] = useState(false);

  const db = getFirestore();
  const storage = getStorage();

  useEffect(() => {
    const fetchPhotos = async () => {
      const photosCollection = collection(db, `Shows/${show.id}/Photos`);
      const photoDocs = await getDocs(photosCollection);
      const fetchedPhotos = [];

      for (const doc of photoDocs.docs) {
        const photoData = doc.data();
        const storageRef = ref(
          storage,
          `showPhotos/${show.season}/${show.id}/${doc.id}`
        );
        const url = await getDownloadURL(storageRef);

        // Obtener las dimensiones originales de las imágenes
        const img = new Image();
        img.src = url;

        await new Promise((resolve) => {
          img.onload = () => {
            const width = img.width;
            const height = img.height;

            fetchedPhotos.push({
              src: url,
              width: width,
              height: height,
              description: photoData.description,
            });

            resolve();
          };
        });
      }

      setPhotos(fetchedPhotos);
    };

    fetchPhotos();
  }, [db, storage, show.id, show.season]);

  // Abrir modal con imagen seleccionada
  const openModal = (event, { index }) => {
    setCurrentImage(photos[index]); // Almacenar la imagen seleccionada
    setIsModalOpen(true);
  };

  // Cerrar modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <Gallery height="500" photos={photos} onClick={openModal} />

      <Modal
        show={isModalOpen}
        onHide={closeModal}
        centered
        size="lg"
        className="custom-modal"
      >
        <Modal.Body>
          {currentImage && (
            <div className="image-container">
              <img
                src={currentImage.src}
                alt={currentImage.description || "Imagen"}
                className="img-fluid"
              />
              <div className="image-description">
                {currentImage.description}
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default SVShowPhotoGallery;
