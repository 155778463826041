import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
  orderBy,
} from "firebase/firestore/lite";
import SVFormularioObra from "./SVFormularioObra";
import SVLinkObra from "./SVLinkObra";
import { SeasonsList } from "./Constants.js";
import { calculateSeason } from "./Utils.js";

function SVConfig() {
  const [shows, setShows] = useState();
  const [showSelect, setShowSelect] = useState();
  const [season, setSeason] = useState(calculateSeason());

  useEffect(() => {
    const db = getFirestore();
    const queryShows = query(
      collection(db, "Shows"),
      where("season", "==", season.replace("-", "-20")),
      orderBy("premiereDate", "desc")
    );
    getDocs(queryShows).then((res) => {
      const wShows = [];
      res.forEach((doc) => {
        wShows.push({ ...doc.data(), id: doc.id });
      });
      setShows(wShows);
    });
  }, [showSelect, season]);

  return (
    <>
      <Container className="mt-2">
        <Row className="mt-2">
          <h2 className="SvTitulo">Configuració</h2>
          <hr />
        </Row>
        {showSelect ? (
          <SVFormularioObra
            close={() => setShowSelect(null)}
            dataShow={showSelect}
            season={season}
          />
        ) : (
          <div>
            <div className="mb-3 ">
              <select
                className="form-select d-inline secondary w-auto"
                name="season"
                onChange={(e) => setSeason(e.target.value)}
                value={season}
              >
                {SeasonsList.map((element) => {
                  return <option value={element}>{element}</option>;
                })}
              </select>
            </div>
            {shows ? (
              <ul className="list-group ">
                {shows.map((show) => (
                  <>
                    <SVLinkObra
                      dataShow={show}
                      showSelect={() => setShowSelect(show)}
                    />
                  </>
                ))}
              </ul>
            ) : (
              ""
            )}
            <button
              className="btn btn-primary mt-3 boton-redCorporative"
              onClick={() => setShowSelect(" ")}
            >
              Nou espectacle
            </button>
          </div>
        )}
      </Container>
    </>
  );
}

export default SVConfig;
