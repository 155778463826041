import { React } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import "./Styles/SVComponents.css";
import SVButtonPlay from "./SVButtonPlay";
import { useNavigate } from "react-router-dom";
import SVShowPhotos from "./SVShowPhotos";

function SVShowPC(props) {
  const navigate = useNavigate();

  const handleVideoClick = (linkId) => {
    navigate("/obre/" + props.show.id + "/video/" + linkId);
  };

  return (
    <>
      {props.show && (
        <Container className="mt-2">
          <Row className="mt-2">
            <Col>
              <div className="text-center">
                <h2 className="SvTitulo">{props.show.title}</h2>
                <h5 className="SvTitulo fst-italic">
                  Direcció: {props.show.directors}
                </h5>
              </div>
            </Col>
            <hr />
          </Row>
          <Row>
            <Col>
              <Image
                rounded="true"
                src={props.show.refPosterImage}
                className="shadow w-75"
              />
            </Col>
            <Col>
              <div className="parrafo">{props.show.description}</div>
              <div className="d-flex justify-content-around">
                {[1, 2, 3].map((id) => {
                  const descriptionLink = props.show["descriptionLink" + id];
                  if (descriptionLink && descriptionLink.trim().length > 0) {
                    return (
                      <SVButtonPlay
                        key={id}
                        selectVideo={handleVideoClick}
                        idVideo={id}
                        descriptionLink={descriptionLink}
                      />
                    );
                  }
                  return null;
                })}
              </div>
              <div className="d-flex justify-content-around">
                {[4, 5, 6].map((id) => {
                  const descriptionLink = props.show["descriptionLink" + id];
                  if (descriptionLink && descriptionLink.trim().length > 0) {
                    return (
                      <SVButtonPlay
                        key={id}
                        selectVideo={handleVideoClick}
                        idVideo={id}
                        descriptionLink={descriptionLink}
                      />
                    );
                  }
                  return null;
                })}
              </div>
            </Col>
          </Row>
          <SVShowPhotos show={props.show} usuario={props.usuario} />
        </Container>
      )}
    </>
  );
}

export default SVShowPC;
