import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import SVShow from "../components/SVShow";
import SVInfoSub from "../components/Usuarios/SVInfoSub";
import { getFirestore, getDoc, doc } from "firebase/firestore/lite";

function SVShowsPage({ usuario }) {
  const { id } = useParams();
  const [show, setShow] = useState();

  useEffect(() => {
    const db = getFirestore();
    getDoc(doc(db, "Shows", id)).then((res) => {
      setShow({ ...res.data(), id: id });
    });
  }, [id]);

  return (
    <>
      {usuario.isSubscriptionActive ? (
        <SVShow show={show} usuario={usuario} />
      ) : (
        <SVInfoSub />
      )}
    </>
  );
}

export default SVShowsPage;
