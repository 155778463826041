import { React, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import "./Styles/SVComponents.css";
import SVButtonPlay from "./SVButtonPlay";
import { useNavigate } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import SVShowPhotos from "./SVShowPhotos";

function SVShowMovil(props) {
  const navigate = useNavigate();
  const [idVideoVisible, setIdVideoVisible] = useState(1);

  const handleVideoClick = (linkId) => {
    navigate("/obre/" + props.show.id + "/video/" + linkId);
  };

  const handleSwipeRight = () => {
    if (idVideoVisible > 1) {
      setIdVideoVisible(idVideoVisible - 1);
    }
  };

  const handleSwipeLeft = () => {
    if (
      props.show[`descriptionLink${idVideoVisible + 1}`] &&
      props.show[`descriptionLink${idVideoVisible + 1}`].trim().length > 0
    ) {
      setIdVideoVisible(idVideoVisible + 1);
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedRight: handleSwipeRight,
    onSwipedLeft: handleSwipeLeft,
  });

  return (
    <>
      <Container className="mt-2">
        <Row className="mt-2">
          <Col>
            <div className="text-center">
              <h2 className="SvTitulo">{props.show.title}</h2>
              <h5 className="SvTitulo fst-italic">
                Direcció: {props.show.directors}
              </h5>
            </div>
          </Col>
          <hr />
        </Row>
        <Row className="justify-content-center">
          <Image
            rounded="true"
            src={props.show.refPosterImage}
            className="w-100"
          />
        </Row>
        <Row>
          <Col>
            <div
              className="d-flex justify-content-center align-items-center"
              {...swipeHandlers}
            >
              {props.show[`descriptionLink${idVideoVisible}`] && (
                <>
                  {props.show[`descriptionLink${idVideoVisible - 1}`] && (
                    <div className="transperente">
                      <FaChevronLeft />
                    </div>
                  )}
                  <SVButtonPlay
                    selectVideo={handleVideoClick}
                    idVideo={idVideoVisible}
                    descriptionLink={
                      props.show[`descriptionLink${idVideoVisible}`]
                    }
                  />
                  {props.show[`descriptionLink${idVideoVisible + 1}`] &&
                    props.show[`descriptionLink${idVideoVisible + 1}`].trim()
                      .length > 0 && (
                      <div className="transperente">
                        <FaChevronRight />
                      </div>
                    )}
                </>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <div className="parrafo">{props.show.description}</div>
        </Row>
        <SVShowPhotos show={props.show} usuario={props.usuario} />
      </Container>
    </>
  );
}

export default SVShowMovil;
