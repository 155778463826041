import { React, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Styles/SVComponents.css";
import { Modal, Button, Form } from "react-bootstrap";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import SVShowPhotoGallery from "./SVShowPhotoGallery";

function SVShowPhotos(props) {
  const [showModalUpladPhoto, setShowModalUploadPhoto] = useState(false);
  const [photoDescription, setPhotoDescription] = useState("");
  const [photoFile, setPhotoFile] = useState(null);

  const storage = getStorage();
  const db = getFirestore();

  const handleFormAddPhotoSubmit = async (e) => {
    e.preventDefault();
    const id = await savePhoto();

    const spaceRef = ref(
      storage,
      "showPhotos/" + props.show.season + "/" + props.show.id + "/" + id
    );

    uploadBytes(spaceRef, photoFile).then((snapshot) => {
      /* getDownloadURL(snapshot.ref).then((downloadURL) => {
        //this.setState({ refPosterImage: downloadURL });
        console.log(downloadURL);
      });*/

      setPhotoDescription("");
      setPhotoFile(null);
    });

    setShowModalUploadPhoto(false);
  };

  const savePhoto = async () => {
    const docRef = await addDoc(
      collection(db, `Shows/${props.show.id}/Photos`),
      {
        description: photoDescription,
        uploadDate: new Date(),
        isValidated: false,
        user: props.usuario.email,
      }
    );

    return docRef.id;
  };

  return (
    <>
      {props.usuario.isAdmin && (
        <>
          <Row className="mt-2">
            <Col>
              <div className="position-relative text-center">
                <h2 className="SvTitulo">Fotografies</h2>
                <i
                  className="bi bi-plus-circle-fill add-photo-icon"
                  onClick={() => setShowModalUploadPhoto(true)}
                  title="Afegeix nova fotografia"
                ></i>
              </div>
            </Col>
            <hr />
            <SVShowPhotoGallery show={props.show} />
          </Row>
          <Modal
            show={showModalUpladPhoto}
            onHide={() => setShowModalUploadPhoto(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title> {props.show.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleFormAddPhotoSubmit}>
                <Form.Group controlId="formPhotoName">
                  <Form.Label>Descripció de la foto</Form.Label>

                  <Form.Control
                    className=""
                    type="text"
                    placeholder="Descripció"
                    value={photoDescription}
                    onChange={(e) => setPhotoDescription(e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="formFile" className="mt-3">
                  <Form.Label>Adjunta un arxiu</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) => setPhotoFile(e.target.files[0])}
                    required
                  />
                </Form.Group>

                <Button
                  variant="primary"
                  type="submit"
                  className="mt-4 boton-redCorporative"
                >
                  Desar fotografia
                </Button>
              </Form>
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
}

export default SVShowPhotos;
